<template>
  <multiselect
    v-model="model"
    :options="options"
    :label="label"
    :track-by="trackBy"
    :close-on-select="false"
    :option-height="40"
    :showLabels="false"
    :searchable="true"
    :multiple="true"
    :placeholder="placeholder"
    class="multiple with-border"
    :limit="inputLimit"
    openDirection="bottom"
    :disabled="disabled"
    @search-change="handleSearchOptionsDebounce"
    @select="$emit('select', model)"
    @input="$emit('input', model)"
  >
    <template slot="caret">
      <div class="chevron">
        <ChevronDown />
      </div>
    </template>
    <template slot="selection" slot-scope="{ values }">
      <div class="d-flex inline-flex">
        <ItemChip v-for="(val, index) in values" :key="index">
          <template slot="text">
            <p
              style="
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                max-width: 80px;
              "
            >
              {{ val.label }}
            </p>
          </template>
        </ItemChip>
        <ItemChip v-if="model.length > inputLimit" :removable="false">
          <template slot="text">
            <p class="mr-1">+{{ model.length - inputLimit }}</p>
          </template>
        </ItemChip>
      </div>
    </template>
    <template slot="noOptions"> Nenhuma opção, tente pesquisar </template>
    <template slot="noResult"> Nenhum resultado encontrado </template>
  </multiselect>
</template>

<script>
import { debounce } from 'lodash'
export default {
  name: 'SearchableMultiselect',
  components: {
    ItemChip: () => import('@/components/General/ItemChip.vue'),
    ChevronDown: () => import('@/assets/icons/chevron-down.svg')
  },
  props: {
    options: {
      type: Array
    },
    inputLimit: {
      type: Number,
      default: 5
    },
    disabled: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: 'Pesquisar...'
    },
    selected: {
      type: Array
    },
    label: {
      type: String,
      default: 'label'
    },
    trackBy: {
      type: String,
      default: 'value'
    }
  },
  data() {
    return {
      model: [],
      handleSearchOptionsDebounce: Function
    }
  },
  created() {
    this.handleSearchOptionsDebounce = debounce(this.handleSearchOptions, 500)
  },
  methods: {
    handleSearchOptions(query) {
      this.$emit('search', query)
    }
  },
  watch: {
    selected: function (val) {
      this.model = val
    }
  }
}
</script>
